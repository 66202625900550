<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">考试查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlExamination"
          style="align-items: flex-start;"
        >
          <div class="searchbox" style="display: flex; align-items: center">
            <div
              title="学员搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">学员搜索:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="item in userList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div
              title="班级搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">班级搜索:</span>
              <el-select
                v-model="projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superPorjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="item in projectList"
                  :key="item.projectId"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
               <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">试卷名称:</span>
              <el-input
                v-model="paperName"
                type="text"
                size="small"
                placeholder="请输入试卷名称"
                clearable
              />
            </div>
          </div>
            <div
              class="searchbox"
              style="margin-top:10px"
            >
              <div title="课件名称" class="searchboxItem ci-full">
                <span class="itemLabel">课件名称:</span>
                <el-input
                  v-model="catalogName"
                  type="text"
                  size="small"
                  placeholder="请输入课件名称"
                  clearable
                />
              </div>
              <div title="考试类型" class="searchboxItem ci-full">
                <span class="itemLabel" style="width:80px">考试类型:</span>
                <el-select
                  v-model="paperUserType"
                  placeholder="请选择"
                  clearable
                  size="small"
                >
                  <el-option
                    v-for="item in paperusetype"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
               <div title="是否及格" class="searchboxItem ci-full">
                <span class="itemLabel" style="width:80px">是否及格:</span>
                <el-select v-model="paperIsPass" placeholder="请选择" clearable size="small">
                  <el-option
                    v-for="item in PassNot"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="getExport()">导出</el-button>
            </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="60"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                width="80"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                width="200"
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                width="200"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="200"
              />
              <el-table-column
                label="班级名称"
                width="200"
                align="left"
                show-overflow-tooltip
                prop="projectName"
              />
              <el-table-column
                label="班级编码"
                width="200"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
              />
              <el-table-column
                label="课程名称"
                width="250"
                align="left"
                show-overflow-tooltip
                prop="courseName"
              />
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                prop="catalogName"
                width="300"
              >
                <template slot-scope="scope">{{
                  scope.row.catalogName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="试卷名称"
                align="left"
                show-overflow-tooltip
                prop="paperName"
                width="350"
              />
              <el-table-column
                label="考试类型"
                align="center"
                show-overflow-tooltip
                prop="paperUserType"
                width="80"
              >
                <template slot-scope="scope">{{
                    $setDictionary("PAPERUSERTYPE", scope.row.paperUserType)
                }}</template>
              </el-table-column>
              <el-table-column
                label="考试时间"
                class="cell2"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                width="150"
              />
              <el-table-column
                label="成绩"
                align="right"
                show-overflow-tooltip
                prop="paperScore"
                width="80"
              />
              <el-table-column
                label="是否及格"
                align="center"
                width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.paperIsComplete ? "是" : "否"
                }}</template>
              </el-table-column>
              <el-table-column
                label="正确率"
                align="right"
                width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.accuracyStr || "0%"
                }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handledetail(scope.row)"
                    >答题详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { Message } from "element-ui";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/Examination",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectId: "",
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      dialogVisible: false,
      catalogName: "",
      paperName: "",
      compId: "",
      CompanyList: [],
      paperUserType: "",
      paperusetype: [],
      PassNot: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      paperIsPass: "",
    };
  },
    doNotInit: true,
  created() {
    this.getTableHeight();
    this.getpaperusertypeList();
    this.superUserSelect();
    this.superPorjectSelect();
  },
  computed: {},
   watch: {
    projectId: function () {
      this.superUserSelect();
    },
    userId: function () {
      this.superPorjectSelect();
    },
     $route: {
      handler: function(val, oldVal) {
           console.log(oldVal.query);
        if (val.query.liuliuliu) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    superPorjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
         userId: this.userId || this.$route.query.userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superPorjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    // 导出
    doExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      ).then(() => {
        let params = {
          catalogName: this.catalogName || "",
        projectId: this.projectId || "",
        userId: this.userId || "",
        paperName: this.paperName || "",
        paperIsComplete: this.paperIsPass,
        paperUserType: this.paperUserType || "",
        };
        this.$post("/biz/paperUser/statisticsPaperListPrint", params).then(
          (res) => {
            if (res.status == "0") {
              window.open(res.data);
            }
          }
        );
      });
    },
    getpaperusertypeList() {
      const studylist = this.$setDictionary("PAPERUSERTYPEQUERY", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.paperusetype = list;
    },
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      let par = {
        catalogName: this.catalogName || "",
        projectId: this.projectId || "",
        userId: this.userId || this.$route.query.userId,
        paperName: this.paperName || "",
        paperIsComplete: this.paperIsPass,
        paperUserType: this.paperUserType || "",
        compId:this.compId
      };
      params = {
        ...params,
        ...par,
      };
      this.doFetch({
        url: "/biz/paperUser/runListPage",
        params,
        pageNum,
      });
    },
     // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
     // 导出
    getExport() {
        if (this.projectId == "") {
        Message.error({
          message: "请先选择班级",
          duration: "1000",
        });
        return;
      }
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
           let params = {
      };
      let par = {
        catalogName: this.catalogName || "",
        projectId: this.projectId || "",
        userId: this.userId || "",
        paperName: this.paperName || "",
        paperIsComplete: this.paperIsPass,
        paperUserType: this.paperUserType || "",
        compId:this.compId
      };
      params = {
        ...params,
        ...par,
      };
          this.$post("/biz/paperUser/runListPageExport", params).then(
            (res) => {
              if (res.status == "0") {
                window.open(res.data);
              }
            }
          );
        })
        .catch(() => {});
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3) * 16;
      if (opDom) {
        // tHeight -= 34 + 40 + 40 + 0.675 * 16 + 1;
        tHeight -= 40 + 0.675 * 16 + 1
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    handledetail(row) {
      sessionStorage.setItem("rowJson", JSON.stringify(row));
      this.$router.push({
        path: "/web/operate/ExaminationDetails",
        query: {
          stu: "examination",
          stu2:'Yw',
          userId:this.userId
        },
      });
    },
  },
   beforeRouteLeave: resetKeepAlive,
  
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.operationControlExamination {
  > div {
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .searchboxs {
    justify-content: flex-start;
  }
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
